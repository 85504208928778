import React from "react";
import { RichTextView } from "@utdanningsdirektoratet/richtext/index";
import { Barnehageloven } from "./Barnehageloven";
import { LovEnumIdentifier, SnapshotResponse } from "../../../Types/Snapshot";
import { getText } from "../../../Overskrifts/Overskrifts";
import { Privatskoleloven } from "./Privatskoleloven";

type IProps = {
  snapshot: SnapshotResponse;
  showComment?: boolean;
};

export const EndeligRapportHoldeTilbakeTilskudd: React.FC<IProps> = ({
  snapshot,
  showComment = true,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonHoldeTilbakeTilskuddVedtak,
  );
  if (!snapshot.reaksjoner?.holdeTilbakeTilskudd?.isValgt) {
    return null;
  }
  return (
    <>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven ? (
        <Barnehageloven snapshot={snapshot} heading={heading} />
      ) : (
        <Privatskoleloven snapshot={snapshot} heading={heading} />
      )}
      {showComment && (
        <RichTextView
          html={snapshot.reaksjoner?.holdeTilbakeTilskudd?.kommentar ?? ""}
        />
      )}
    </>
  );
};
