import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../../Types/Snapshot";
import { Barnehageloven } from "./Barnehageloven";
import { Privatskoleloven } from "./Privatskoleloven";

type IProps = {
  snapshot: SnapshotResponse;
};

export const ForelopigRapportInnledning: React.FC<IProps> = ({ snapshot }) => {
  if (
    snapshot.omTilsynet?.lovEnumIdentifier === LovEnumIdentifier.Barnehageloven
  ) {
    return <Barnehageloven snapshot={snapshot} />;
  }
  return <Privatskoleloven snapshot={snapshot} />;
};
