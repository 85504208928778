import React from "react";
import styled from "styled-components";
import { SnapshotResponse } from "../tekster/Types/Snapshot";
import { ForelopigRapportReaksjonerIngress } from "../tekster/VareReaksjoner/ForelopigRapport/Ingress/ForelopigRapportReaksjonerIngress";
import { EndeligRapportReaksjonerIngress } from "../tekster/VareReaksjoner/EndeligRapport/Ingress/EndeligRapportReaksjonerIngress";
import { ForelopigRapportRetteForholdene } from "../tekster/VareReaksjoner/ForelopigRapport/RetteForholdene/ForelopigRapportRetteForholdene";
import { EndeligRapportRetteForholdene } from "../tekster/VareReaksjoner/EndeligRapport/RetteForholdene/EndeligRapportRetteForholdene";
import { ForelopigRapportKravOmTilbakebetaling } from "../tekster/VareReaksjoner/ForelopigRapport/KravOmTilbakebetaling/ForelopigRapportKravOmTilbakebetaling";
import { EndeligRapportKravOmTilbakebetaling } from "../tekster/VareReaksjoner/EndeligRapport/KravOmTilbakebetaling/EndeligRapportKravOmTilbakebetaling";
import { ForelopigRapportHoldeTilbakeTilskudd } from "../tekster/VareReaksjoner/ForelopigRapport/HoldeTilbakeTilskudd/ForelopigRapportHoldeTilbakeTilskudd";
import { EndeligRapportHoldeTilbakeTilskudd } from "../tekster/VareReaksjoner/EndeligRapport/HoldeTilbakeTilskudd/EndeligRapportHoldeTilbakeTilskudd";
import { ForelopigRapportPermanentStenging } from "../tekster/VareReaksjoner/ForelopigRapport/PermanentStenging/ForelopigRapportPermanentStenging";
import { EndeligRapportPermanentStenging } from "../tekster/VareReaksjoner/EndeligRapport/PermanentStenging/EndeligRapportPermanentStenging";
import { ForelopigRapportTidsbegrensetStenging } from "../tekster/VareReaksjoner/ForelopigRapport/TidsbegrensetStenging/ForelopigRapportTidsbegrensetStenging";
import { EndeligRapportTidsbegrensetStenging } from "../tekster/VareReaksjoner/EndeligRapport/TidsbegrensetStenging/EndeligRapportTidsbegrensetStenging";
import { ForelopigRapportTrekkeTilbakeGodkjenning } from "../tekster/VareReaksjoner/ForelopigRapport/TrekkeTilbakeGodkjenning/ForelopigRapportTrekkeTilbakeGodkjenning";
import { EndeligRapportTrekkeTilbakeGodkjenning } from "../tekster/VareReaksjoner/EndeligRapport/TrekkeTilbakeGodkjenning/EndeligRapportTrekkeTilbakeGodkjenning";
import { ExpandableBox } from "../components/ExpandableBox";
import RapportChapter from "./RapportChapter";
import { Heading2 } from "../components/Headings/Headings";
import { getText } from "../tekster/Overskrifts/Overskrifts";
import { hasBrudd } from "../utils/bruddUtils";

const HeadingWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

type RapportReaksjonerProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
  withExpandable: boolean;
  showComments?: boolean;
  openByDefault?: boolean;
};

const RapportReaksjoner: React.FC<RapportReaksjonerProps> = ({
  snapshot,
  isForelopigRapport,
  withExpandable,
  showComments,
  openByDefault = true,
}) => {
  const rapportHasNoBrudd = !hasBrudd(snapshot.brudd?.kontrollsporsmals ?? []);
  const Body = rapportHasNoBrudd ? (
    <p>
      {getText(snapshot.omTilsynet?.malform, (t) => t.brukerIngenReaksjoner)}
    </p>
  ) : (
    <>
      {isForelopigRapport === true ? (
        <ForelopigRapportReaksjonerIngress snapshot={snapshot} />
      ) : (
        <EndeligRapportReaksjonerIngress snapshot={snapshot} />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportRetteForholdene snapshot={snapshot} />
      ) : (
        <EndeligRapportRetteForholdene snapshot={snapshot} />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportKravOmTilbakebetaling snapshot={snapshot} />
      ) : (
        <EndeligRapportKravOmTilbakebetaling snapshot={snapshot} />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportHoldeTilbakeTilskudd
          snapshot={snapshot}
          showComment={showComments}
        />
      ) : (
        <EndeligRapportHoldeTilbakeTilskudd
          snapshot={snapshot}
          showComment={showComments}
        />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportPermanentStenging
          snapshot={snapshot}
          showComment={showComments}
        />
      ) : (
        <EndeligRapportPermanentStenging
          snapshot={snapshot}
          showComment={showComments}
        />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportTidsbegrensetStenging
          snapshot={snapshot}
          showComment={showComments}
        />
      ) : (
        <EndeligRapportTidsbegrensetStenging
          snapshot={snapshot}
          showComment={showComments}
        />
      )}

      {isForelopigRapport === true ? (
        <ForelopigRapportTrekkeTilbakeGodkjenning
          snapshot={snapshot}
          showComment={showComments}
        />
      ) : (
        <EndeligRapportTrekkeTilbakeGodkjenning
          snapshot={snapshot}
          showComment={showComments}
        />
      )}
    </>
  );
  return withExpandable ? (
    <ExpandableBox
      id="rapport-reaksjoner"
      title={getText(snapshot.omTilsynet?.malform, (t) =>
        isForelopigRapport ? t.reaksjonerForelopigRapport : t.reaksjoner,
      )}
      initialState={openByDefault ? "expanded" : "collapsed"}
    >
      {Body}
    </ExpandableBox>
  ) : (
    <RapportChapter>
      <HeadingWrapper>
        <Heading2>
          {getText(snapshot.omTilsynet?.malform, (t) =>
            isForelopigRapport ? t.reaksjonerForelopigRapport : t.reaksjoner,
          )}
        </Heading2>
      </HeadingWrapper>
      {Body}
    </RapportChapter>
  );
};

export default RapportReaksjoner;
