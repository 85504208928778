import React from "react";
import { Route, Routes, ScrollRestoration } from "react-router-dom";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import Footer from "components/Footer";
import Header from "components/Header";
import { useContextState } from "StateProvider";
import routes from "routes";
import Personvern from "pages/Personvern";
import { Page } from "@utdanningsdirektoratet/layout";
import { SlowAction } from "@utdanningsdirektoratet/slowaction";
import { Tilsyn } from "pages/Tilsyn/Tilsyn";
import { Innsendings } from "pages/SendInnInnsendelse/Innsendings";
import NoMatchingPathShowDefault404 from "pages/NoMatchingPathShowDefault404";
import ErrorPage from "pages/ErrorPage";
import Oversikt from "pages/Oversikt/Oversikt";
import * as Sentry from "@sentry/react";
import { Tilsynsrapport } from "./pages/Rapport/Tilsynsrapport/Tilsynsrapport";
import { HistoricTilsynsrapports } from "./pages/HistoricRapports/HistoricTilsynsrapports";
import { usePageViewTrackingExceptForRoutes } from "./utils/analyticsUtils";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: React.FC = () => {
  const { state } = useContextState();
  if (state.error) {
    return <ErrorPage error={state.error} />;
  }

  usePageViewTrackingExceptForRoutes([
    routes.rapport,
    routes.tilsyn,
    routes.innsending,
  ]);

  return (
    <>
      <ScrollRestoration />
      <SlowAction />
      <Notifications />
      <PopinManager />
      <Page>
        <Header />
        <SentryRoutes>
          <Route path={routes.home} element={<Oversikt />} />
          <Route path={routes.personvern} element={<Personvern />} />
          <Route path={routes.rapport} element={<Tilsynsrapport />} />
          <Route path={routes.historic} element={<HistoricTilsynsrapports />} />
          <Route path={"/oversikt/*"} element={<Oversikt />} />
          <Route path={routes.tilsyn}>
            <Route path="" element={<Tilsyn />} />
            <Route path="innsending" element={<Innsendings />} />
          </Route>
          <Route path="*" element={<NoMatchingPathShowDefault404 />} />
        </SentryRoutes>
        <Footer />
      </Page>
    </>
  );
};

export default App;
