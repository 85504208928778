import React from "react";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { Barnehageloven } from "./Barnehageloven";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { getText } from "../../../Overskrifts/Overskrifts";

type IProps = {
  snapshot: SnapshotResponse;
  showComment?: boolean;
};

export const EndeligRapportPermanentStenging: React.FC<IProps> = ({
  snapshot,
  showComment,
}) => {
  const heading = getText(
    snapshot.omTilsynet?.malform ?? "Bokmål",
    (t) => t.reaksjonStengePermanentVedtak,
  );
  if (!snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
    return null;
  }
  return (
    <>
      <Barnehageloven snapshot={snapshot} heading={heading} />
      {showComment && (
        <RichTextView
          html={
            snapshot.reaksjoner?.permanentStengingAvVirksomhet?.kommentar ?? ""
          }
        />
      )}
      {snapshot.omTilsynet?.lovTittel !== "Barnehageloven" && (
        <b>Reaksjonen permanent stenging gjelder kun for barnehageloven</b>
      )}
    </>
  );
};
