import React from "react";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { Barnehageloven } from "./Barnehageloven";
import { SnapshotResponse } from "../../../Types/Snapshot";

type IProps = {
  snapshot: SnapshotResponse;
  showComment?: boolean;
};

export const ForelopigRapportPermanentStenging: React.FC<IProps> = ({
  snapshot,
  showComment = true,
}) => {
  if (!snapshot.reaksjoner?.permanentStengingAvVirksomhet?.isValgt) {
    return null;
  }
  return (
    <>
      <Barnehageloven snapshot={snapshot} />
      {showComment && (
        <RichTextView
          html={
            snapshot.reaksjoner?.permanentStengingAvVirksomhet?.kommentar ?? ""
          }
        />
      )}
      {snapshot.omTilsynet?.lovTittel !== "Barnehageloven" && (
        <b>Reaksjonen permanent stenging gjelder kun for barnehageloven</b>
      )}
    </>
  );
};
