import React from "react";
import { SnapshotResponse } from "../../Types/Snapshot";
import { reaksjonerCombiner } from "../../../utils/reaksjonerCombiner";
import { hasBrudd } from "../../../utils/bruddUtils";
import { RapportIngressInnledning } from "../Common/RapportIngressInnledning";
import { RedigerbarTekst } from "../../../Rapport/RedigerbarTekst";

type IProps = {
  snapshot: SnapshotResponse;
};

export const EndeligRapportOverordnetIngress: React.FC<IProps> = ({
  snapshot,
}) => {
  const reaksjoner = reaksjonerCombiner(snapshot);
  const rapportHasBrudd = hasBrudd(snapshot.brudd?.kontrollsporsmals ?? []);

  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <RedigerbarTekst
        navn="EndeligRapportOverordnetIngress"
        snapshot={snapshot}
      >
        <p>
          <RapportIngressInnledning snapshot={snapshot} />
          {rapportHasBrudd
            ? ` Vi har avdekka brot på regelverket og vedtar ${reaksjoner}. Dette er eit enkeltvedtak etter forvaltningslova § 2 første ledd bokstav b.`
            : " Vi har ikkje avdekka brot på regelverket."}
        </p>
      </RedigerbarTekst>
    );
  }

  return (
    <RedigerbarTekst navn="EndeligRapportOverordnetIngress" snapshot={snapshot}>
      <p>
        <RapportIngressInnledning snapshot={snapshot} />
        {rapportHasBrudd
          ? ` Vi har avdekket brudd på regelverket og vedtar ${reaksjoner}. Dette er et enkeltvedtak etter forvaltningsloven § 2 første ledd bokstav b.`
          : " Vi har ikke avdekket brudd på regelverket."}
      </p>
    </RedigerbarTekst>
  );
};
