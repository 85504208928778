import React from "react";
import {
  SnapshotResponse,
  TilsynsRapportComputedMetadata,
} from "../tekster/Types/Snapshot";
import RapportTittel from "./RapportTittel";
import RapportInnledning from "./RapportInnledning";
import RapportBrudd from "./RapportBrudd";
import RapportReaksjoner from "./RapportReaksjoner";
import RapportOppfolging from "./RapportOppfolging";
import RapportRegelverkOgVurdering from "./RapportRegelverkOgVurdering/RapportRegelverkOgVurdering";
import {
  RedigerbarTekstContext,
  RenderRedigerbarTekstFunction,
} from "./RedigerbarTekstContext";

type IProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
  innsendingFristDatoUtc?: Date;
  tilsynsRapportMetadata?: TilsynsRapportComputedMetadata;
  withoutObfuscationBackground?: boolean;
  alwaysShowAllKontrollsporsmalsInBrudd?: boolean;
  openByDefault?: boolean;
  renderRedigerbarTekst?: RenderRedigerbarTekstFunction;
};

export const RapportDokument: React.FC<IProps> = ({
  snapshot,
  isForelopigRapport,
  innsendingFristDatoUtc,
  tilsynsRapportMetadata,
  withoutObfuscationBackground,
  alwaysShowAllKontrollsporsmalsInBrudd,
  openByDefault = true,
  renderRedigerbarTekst,
}) => {
  return (
    <RedigerbarTekstContext.Provider value={renderRedigerbarTekst}>
      <RapportTittel
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
      />

      <RapportInnledning
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
        tilsynsRapportMetadata={tilsynsRapportMetadata}
        openByDefault={openByDefault}
      />

      <RapportBrudd
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
        withExpandable
        alwaysShowAllKontrollsporsmals={alwaysShowAllKontrollsporsmalsInBrudd}
        openByDefault={openByDefault}
      />

      <RapportReaksjoner
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
        withExpandable
        openByDefault={openByDefault}
      />

      <RapportOppfolging
        snapshot={snapshot}
        isForelopigRapport={isForelopigRapport}
        innsendingFristDatoUtc={innsendingFristDatoUtc}
        withExpandable
        withTitle
        openByDefault={openByDefault}
      />

      <RapportRegelverkOgVurdering
        snapshot={snapshot}
        withoutObfuscationBackground={withoutObfuscationBackground}
        openByDefault={openByDefault}
        isForelopigRapport={isForelopigRapport}
      />
    </RedigerbarTekstContext.Provider>
  );
};
