import React from "react";
import styled from "styled-components";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { DATE_FORMAT } from "SharedComponents/utils/date";
import { TilsynTittel } from "SharedComponents/TilsynTittel";
import { RapportDokument } from "SharedComponents/Rapport/RapportDokument";
import { generatePath, Link, useParams } from "react-router-dom";
import { Link as UrcLink } from "@utdanningsdirektoratet/link";
import { Icon } from "@utdanningsdirektoratet/icon";
import { MainPanel as MP } from "@utdanningsdirektoratet/layout";
import colors from "@utdanningsdirektoratet/styles/colors";
import { getUrls } from "globalFunctions";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import { Heading2 } from "SharedComponents/components/Headings/Headings";
import { Loader } from "SharedComponents/components/Loader";
import { useGetPublisertTilsyn } from "../../../hooks/getters/tilsyn";
import { PubliseringMalType } from "../../../ApiClients";
import routes from "../../../routes";
import { LenkeTilSendInnInnsending } from "../components/LenkeTilSendInnInnsending/LenkeTilSendInnInnsending";
import { TilgangMessage } from "../../../components/TilgangMessage";
import { usePageViewTrackingWhenTilsynLoaded } from "../../../utils/analyticsUtils";

const Publisert = styled.p`
  margin: 0 0 1rem;
`;

const ContentBox = styled.div`
  max-width: 50rem;
  margin: 0 auto;
`;

const MainPanel = styled(MP)`
  padding-bottom: 4rem;
  @media (max-width: 979px) {
    padding-bottom: 2rem;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DokumenterHeader = styled(Heading2 as any)`
  border-bottom: 1.5px solid ${colors.green5};
  padding-bottom: 0.5rem;
  margin-top: 2rem;
`;

export const Tilsynsrapport: React.FC = () => {
  const { tilsynId } = useParams<"tilsynId">() as { tilsynId: string };
  const { data: tilsyn } = useGetPublisertTilsyn(tilsynId);

  usePageViewTrackingWhenTilsynLoaded(tilsyn);

  if (tilsyn === undefined) {
    return <Loader />;
  }

  const getLinkTittel = () => {
    if (
      tilsyn.publiseringMalType === PubliseringMalType.ForelopigTilsynsrapport
    ) {
      return getText(
        tilsyn.snapshot.omTilsynet?.malform,
        (t) => t.lastNedForelopigRapport,
      );
    }
    if (tilsyn.publiseringMalType === PubliseringMalType.Tilsynsrapport) {
      return getText(
        tilsyn.snapshot.omTilsynet?.malform,
        (t) => t.lastNedEndeligRapport,
      );
    }

    return null;
  };

  return (
    <MainPanel color="white">
      <UrcLink iconLeft="angle left" text="Oversikt tilsyn">
        {(props) => (
          <Link
            to={generatePath(routes.tilsyn, { tilsynId: tilsyn.tilsynId })}
            className={props.className}
          >
            {props.children}
          </Link>
        )}
      </UrcLink>

      <ContentBox>
        <TilsynTittel snapshot={tilsyn.snapshot} />

        <Publisert className="u--marginTop1-5">
          {tilsyn.publisertDatoUtc
            ? `Publisert ${formatDate(tilsyn.publisertDatoUtc, DATE_FORMAT)}`
            : "Denne rapporten er ikke publisert enda"}
        </Publisert>

        <RapportDokument
          snapshot={tilsyn.snapshot}
          isForelopigRapport={
            tilsyn.publiseringMalType ===
            PubliseringMalType.ForelopigTilsynsrapport
          }
          innsendingFristDatoUtc={tilsyn.innsendingFristDatoUtc}
          tilsynsRapportMetadata={tilsyn.tilsynsRapportComputedMetadata}
          withoutObfuscationBackground
          openByDefault={false}
        />

        <DokumenterHeader>Dokumenter</DokumenterHeader>
        <Icon
          className="Icon--darkblue"
          type="small"
          icon="text document"
          placement="before"
        />
        <a
          className="Link"
          href={`${getUrls().downloadRapport}/${tilsyn.publiseringId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getLinkTittel()}
        </a>

        <div className="u--marginTop3">
          <LenkeTilSendInnInnsending tilsyn={tilsyn} />
        </div>
        <TilgangMessage tilgangType={tilsyn.tilgangType} />
      </ContentBox>
    </MainPanel>
  );
};
