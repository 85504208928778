import React from "react";
import {
  DetailedList,
  DetailedListCell,
  DetailedListRow,
} from "@utdanningsdirektoratet/detailedlist";
import styled from "styled-components";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { capitalize } from "../../../../utils/stringUtils";
import { SnapshotResponse } from "../../../Types/Snapshot";
import { Heading4 } from "../../../../components/Headings/Headings";
import { formatCurrency } from "../../../../utils/formatCurrency";

const TemaTitle = styled(Heading4)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: Montserrat;
`;

const TotalSumText = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  margin-left: auto;
  width: 50%;
  display: flex;
  justify-content: space-between;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
`;

const ReaksjonKommentar = styled(RichTextView)`
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

export const getTilbakebetalingsKontoText = (
  totalSum: number,
  malform: string,
  isForelopigRapport: boolean,
  lovTittel?: string,
  tilsynssubjekt?: string,
): string => {
  if (isForelopigRapport) {
    return "";
  }
  if (lovTittel === "Barnehageloven") {
    return malform === "Nynorsk"
      ? `barnehagen skal tilbakebetale kr ${formatCurrency(
          totalSum,
        )} til kommunen. De vil få tilsendt nærmare betalingsinformasjon frå kommunen.`
      : `barnehagen skal tilbakebetale kr ${formatCurrency(
          totalSum,
        )} til kommunen. Dere vil få tilsendt nærmere betalingsinformasjon fra kommunen.`;
  }
  return `${tilsynssubjekt} skal tilbakebetale kr ${formatCurrency(totalSum)} til Utdanningsdirektoratet på kontonummer 7694 05 10879.`;
};

const tilbakebetalingsKravsTitle = (
  <DetailedListRow header>
    <DetailedListCell size="80%" left>
      Beskrivelse
    </DetailedListCell>
    <DetailedListCell size="20%" right>
      Beløp
    </DetailedListCell>
  </DetailedListRow>
);

type IProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
};

export const ReaksjonKravOmTilbakebetaling: React.FC<IProps> = ({
  snapshot,
  isForelopigRapport,
}) => {
  const kravOmTilbakebetalingReaksjon =
    snapshot.reaksjoner?.kravOmTilbakebetaling;
  return (
    <>
      {kravOmTilbakebetalingReaksjon?.tilsynstemas.map((tema) => (
        <div key={tema.id}>
          {tema.tilbakebetalingkravs.length > 0 && (
            <>
              <TemaTitle>{tema.tittel}</TemaTitle>

              <DetailedList title={tilbakebetalingsKravsTitle}>
                {tema.tilbakebetalingkravs.map((krav) => (
                  <div key={krav.id}>
                    <DetailedListRow color="grey">
                      <DetailedListCell left>
                        {krav.beskrivelse}
                      </DetailedListCell>
                      <DetailedListCell right>
                        {formatCurrency(krav.belop)}
                      </DetailedListCell>
                    </DetailedListRow>
                  </div>
                ))}
              </DetailedList>
            </>
          )}
        </div>
      ))}
      <TotalSumText>
        <span>Totalt</span>{" "}
        <span>
          kr {formatCurrency(kravOmTilbakebetalingReaksjon?.totalSum)}
        </span>
      </TotalSumText>
      <p>
        {capitalize(
          getTilbakebetalingsKontoText(
            snapshot.reaksjoner?.kravOmTilbakebetaling?.totalSum ?? 0,
            snapshot.omTilsynet?.malform ?? "Bokmål",
            isForelopigRapport,
            snapshot.omTilsynet?.lovTittel,
            snapshot.omTilsynet?.tilsynsrettssubjektNavn,
          ),
        )}
      </p>
      {kravOmTilbakebetalingReaksjon?.kommentar && (
        <ReaksjonKommentar html={kravOmTilbakebetalingReaksjon?.kommentar} />
      )}
    </>
  );
};
