import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  Heading1,
  Heading2,
  Heading3,
} from "SharedComponents/components/Headings/Headings";
import { MainPanel as MP } from "@utdanningsdirektoratet/layout";

const MainPanel = styled(MP)`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
`;

const NavLinkWrapper = styled.div`
  padding: 1rem 0;
`;

const Personvern: React.FC = () => {
  return (
    <MainPanel color="white">
      <NavLinkWrapper>
        <NavLink to="/">Til forsiden</NavLink>
      </NavLinkWrapper>
      <Heading1>Personvern og informasjonskapsler</Heading1>
      <Heading2>Vi bruker informasjonskapsler</Heading2>
      <p>
        En informasjonskapsel, også kjent som cookie, er en liten tekstfil som
        blir lagret på datamaskinen, lesebrettet eller telefonen din av
        nettstedet du besøker. Slik kan nettsiden huske handlingene eller
        preferansene dine over tid.
      </p>
      <p>Vi bruker informasjonskapsler til følgende formål:</p>
      <Heading3>Innsamling av statistikk</Heading3>
      <p>
        Vi samler inn anonymiserte data for å analysere bruksmønsteret på
        nettsiden. Data som blir innhentet inkluderer bla. teknisk informasjon
        om datamaskinen og Internett-tilkoblingen til brukerne, hvilke knapper
        og lenker som brukerne klikker på, og brukernes geografiske plassering
        ned til nærmeste by. Denne informasjonen brukes for å forbedre
        tjenestetilbudet og brukervennligheten. Kun våre analyseverktøy (Matomo)
        har tilgang til informasjonen. Informasjonen kan ikke brukes for å spore
        deg.
      </p>
      <p>
        Dersom du ikke ønsker at vi skal gjøre dette, så kan du skru av
        informasjonskaplser i din nettleser. Slik skrur du av
        informasjonskapsler for følgende nettlesere:{" "}
        <a href="https://support.google.com/accounts/answer/61416?hl=no">
          Chrome
        </a>
        ,{" "}
        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
          Safari
        </a>{" "}
        og{" "}
        <a href="https://support.mozilla.org/no/kb/aktiver-og-deaktiver-infokapsler">
          Firefox
        </a>
        . (Å skru av informasjonskapsler kan påvirke din brukeropplevelse av
        andre nettsider.)
      </p>
    </MainPanel>
  );
};

export default Personvern;
